<!-- Finder particular de Cementerios -->

<template>
  <div class="ciac_F" v-if="schema">
    <div class="conflex" style="justify-content:center">
      <v-sheet :elevation="4">

        <!-- Cabecera -->
        <div class="cabecera" v-if="Entorno.header.header">
          <base_Header 
            :componenteTipo="componenteTipo"
            :Entorno="Entorno.header"
            @onEvent="event_Header">
          </base_Header>
        </div>

        <!-- Filtro -->
        <div class="contenedor" style="width:800px">       
          <div class="conflex">
            <v-text-field
              style="flex: 0 0 35rem;padding-right:1px"
              v-bind="$input"
              v-model="schema.ctrls.nombre.value"
              :label="schema.ctrls.nombre.label"
              @keypress.13="buscarExec('buscarCementerio')">            
            </v-text-field>

            <v-btn
              v-bind="$cfg.btn.busca"
              @click="buscarExec('buscarCementerio')">            
                <v-icon>{{ $cfg.btn.busca.icono }}</v-icon>
            </v-btn>
          </div>

          <div class="conflex">
            <div style="flex: 0 0 35rem;padding-right:1px">
              <ctrlfinder finderName="atlas_F" :schema="schema.ctrls.atlas"> </ctrlfinder>
            </div>
          </div>

          <!-- Grid -->
          <base_Fgrid 
            :padre="stName" 
            :Entorno="Entorno" 
            dense
            @onEvent="event_Grid">

            <!-- Slot botonera Top -->
            <template v-slot:top_btns="{ Sitem }">         
              <div style="padding:10px 0 0 10px;">
                <btracombi
                  :btra_permisos="schema.btra"
                  :btra_particular="Entorno.btra"
                  btra_modulo="top"
                  @onEvent="event_btra_Top({ accion:$event.accion, row:Sitem })">
                </btracombi>
              </div>
            </template>

            <!-- Slot campo acciones del Grid -->
            <template v-slot:acciones="{ Sitem }">
              <btracombi
                :btra_permisos="schema.btra"
                :btra_particular="Entorno.btra"
                btra_modulo="mrow"
                @onEvent="event_btra_Row({ accion:$event.accion, row:Sitem })">
              </btracombi>
            </template>
          </base_Fgrid>
        </div>
      </v-sheet>

      <!-- Mto como ventana modal -->      
      <v-dialog
        v-model="modal">
          <div class="centrado">             
            <component                 
                :is="component_M"
                :padre="stName"
                :accion="accion"
                :accionRow="accionRow"
                :disparo="disparo"
                @onEvent="event_Mto">
            </component>           
          </div>
      </v-dialog>  
    </div>
  </div>
</template>



<script>

  import { mixinFinder } from "@/mixins/mixinFinder.js";
  import plugs from "@/common/general_plugs";
  const base_Header = () => plugs.groute("base_Header.vue", "base");
  const base_Fgrid = () => plugs.groute("base_Fgrid_maub.vue", "base");
  const btracombi = () => plugs.groute("btracombi.vue", "comp");
  const ctrlfinder = () => plugs.groute("ctrlfinder.vue", "comp");
  const cemen_M= () => plugs.groute('cemen_M.vue', 'comp');

  export default {
    mixins: [mixinFinder],
    components: { base_Header,base_Fgrid, btracombi, ctrlfinder, cemen_M },
    props: {
      padre: { type:String, default: ''},
      componenteTipo: { type:String, default:'FM' },
      Entorno: { type: Object, default: function() { return JSON.parse(JSON.stringify(this.$cfg.base.F)); }}
    },

    data() {
      return {
        schema:null,
        api:'cemen_F',
        stName:'stFcemen',
        component_M:null,
        modal:false,
        accion:'',
        accionRow: {},        
        disparo:false,

        headers: {}
      };
    },

    methods: {
      // configuro entorno particular
      ini_data() {
       console.log("DEV " + this.api + " ********************** (ini_data)");

        // guardo Schema del store en variable local
        this.schema = this.$store.state[this.stName].schema;

        // entorno
        this.Entorno.header.titulo = "Cementerios";        
        this.headers = {
          header: [
                { text: "Acc", value: "acciones", sortable: false, width: "10%", slot:true },
                { text: "Cementerio", value: "name", width: "40%" },
                { text: "Actualizado", value: "modificado", width: "10%" },
                { text: "Origen", value: "atlas", width: "40%" }               
              ]
        };

        this.Entorno.grid.headers = this.set_headers();

        // guardo nombre componente de Mto
        if (this.componenteTipo.indexOf('M')>= 0) this.component_M= 'cemen_M';
      },

    }
  };
</script>
